
  .movieList {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }

