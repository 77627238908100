@import "../../../styles/variables.scss";

.container__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 140px;
  .img-bg {
    position: fixed;
    z-index: -10;
    
    height: auto;
    object-fit: cover;
    filter: blur(5px);
  }
  .card-d {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 25px;
    margin: 0;
    z-index: 10;
    width: 280px;
    height: fit-content;
    background-color: map-get($colors, D);
    border-radius: map-get($b-radius, 2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid map-get($colors, A);
    .card-header {
      align-items: center;
      .icon-card {
        height: 72px;
      }
    }
    .card-body {
      .title{
        font-size: map-get($headings, h2);
        color: $textcolor;
        text-align: center;
        margin-bottom: 10px;
      }
      .error {
        font-size: map-get($headings, p);
        color: red;
        text-align: center;
      }
      .container__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form__input {
          background-color: map-get($colors, B);
          color: $textcolor;
          border-color: map-get($colors, A);
          margin: 4px;
        }
      }

      .text-redirect {
        font-size: map-get($headings, p);
        color: $textcolor;
        text-align: center;
        margin-top: 24px;
      }
      .link-redirect {
        margin-top: 24px;
        font-size: map-get($headings, p);
        color: #00b3ff;
        text-align: center;
        text-decoration: underline;
        margin-left: 6px;

      }
    }
  }
}

.cta {
  position: relative;
  margin: auto;
  margin-top: 24px;
  padding: 15px 25px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: map-get($colors, B);
  width: 55px;
  height: 55px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: $textcolor;
}
.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: $textcolor;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: map-get($colors, C);
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
