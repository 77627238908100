@import "/node_modules/bootstrap/scss/bootstrap.scss";
//Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "variables.scss";
//App
@import "../components/home/Home.scss";
//Navbar
@import "../components/navbar/Navbar.scss";
@import "../components/navbar/favorite/FavoriteCard.scss";
//Auth
@import "../components/auth/signIn/SignIn.scss";
@import "../components/auth/signUp/SignUp.scss";
//Movie Detail
@import "../components/movies/movieDetail/MovieDetail.scss";
//Movie List
@import "../components/movies/movieList/moviesSearch/MoviesSearch.scss";

//Movie Trending
@import "../components/movies/movieList/moviesTrending/MoviesTrending.scss";
//Custom Player
@import "../components/customPlayer/CustomPlayer.scss";

//reset styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #000000;
}

.content-container {
  //restar el tamaño del navbar
  padding-top: 160px;
  max-width: 85%;
  margin: auto;
  min-height: calc(100vh - 160px);
}
