@import "../../../styles/variables.scss";

.dropDownMenu {
  background-color: map-get($colors, C);
  border-radius: map-get($b-radius, 1);
  width: fit-content;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 10px;


  .dropDownItem {
    .moviesFavList {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .card {
        width: 280px;
        height: 120px;
        background-color: map-get($colors, B);
        border-radius: map-get($b-radius, 1);
        display: flex;
        flex-direction: row;
        align-items: center;
        outline: none;
        padding: 10px;

        .card__img {
          height: 100%;
        }

        .card__text {
          width: 100%;
          font-family: "Cairo", sans-serif;
          text-align: center;
          font-size: 19px;
          color: white;
        }

        .card__btn {
          width: 24px;
          margin-left: 5px;

          .btn__icon--size {
            font-size: 28px;
          }
        }
      }
    }
  }
}


.emptyFavText {
  font-size: 22px;
  color: black;

}