.container__bg {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .text__home {
    background-color: map-get($colors, A);
    color: white;
    text-shadow: $textShadowEffect;
    border-radius: map-get($b-radius, 2);
    padding: 12px;
    filter: drop-shadow(0 0 0.75rem #FF00FF);
  }

  .container__bg--url {
    position: fixed;
    z-index: -10;
    top: 160px;
    /* Ajusta la posición superior para evitar superposición con el navbar y el espacio de padding superior */
    left: 0;
    width: 100%;
    height: 100vh;
    /* Resta la altura del navbar y el espacio de padding superior */
    background: url(../../assets/svg/Bg/bgSVG.svg);
    background-position: center;
    opacity: 0.9;
    /* Ajusta la opacidad según sea necesario */
    backdrop-filter: blur(20px);
    overflow: hidden;

  }
}